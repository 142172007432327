<template>
  <div class="app-wrapper" :class="[whitelabel]">
    <RouterView />

    <Footer :whiteText="isOnHomePage" />

    <DevToolbar
      v-if="showDevTools"
      @close="showDevTools = false"
      @playground="devToolsPlayground"
    />

    <notifications />

    <Loader :show="miscStore.showLoader" :sizeInPx="100" />
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from "vue";
  import DevToolbar from "@/components/DevToolbar.vue";
  import { isDebugging } from "@/utils/isDebugging";
  import { useRoute, useRouter } from "vue-router";
  import { RouteName } from "@/typing/enums";
  import { EventBusEventType } from "./utils/eventBus";
  import { eventBus } from "./main";
  import Footer from "./components/Footer.vue";
  import { useWhitelabel } from "./composables/whitelabel";
  import { useMiscStore } from "./stores/misc.store";
  import debounce from "debounce";
  import Loader from "./components/Loader.vue";

  const showDevTools = ref(isDebugging());
  const router = useRouter();
  const route = useRoute();
  const miscStore = useMiscStore();

  const isOnHomePage = computed(() => (route.name as RouteName) === RouteName.Home);
  const whitelabel = computed(() => useWhitelabel());
  const onResize = debounce(() => useMiscStore().setScreenWidth(), 100);

  onMounted(() => {
    document.addEventListener("click", (e) => eventBus.emit(EventBusEventType.click, e));
    document.addEventListener("mousemove", (e) => eventBus.emit(EventBusEventType.mousemove, e));
    window.addEventListener("resize", (e) => {
      eventBus.emit(EventBusEventType.resize, e);
      onResize();
    });
  });

  function devToolsPlayground() {
    if (!isDebugging()) return;

    router.push({ name: RouteName.ComponentPlayground });
  }
</script>

<style lang="scss" scoped>
  .app-wrapper {
    position: relative;
    height: 100vh;
  }
</style>
