<template>
  <Transition name="fade">
    <div v-show="$props.show" class="loader-container">
      <div
        :style="{ width: `${$props.sizeInPx}px`, height: `${$props.sizeInPx}px` }"
        class="loader"
      ></div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  const $props = defineProps({
    show: {
      type: Boolean,
      default: false,
    },
    sizeInPx: {
      type: Number,
      default: 50,
    },
  });
</script>

<style lang="scss" scoped>
  .loader-container {
    position: absolute;
    display: flex;
    z-index: 9999999999999999999999;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid var(--color-primary);
    animation: spin-loader 2s linear infinite;
    -webkit-animation: spin-loader 2s linear infinite; /* Safari */
  }

  @keyframes spin-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Safari */
  @-webkit-keyframes spin-loader {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
</style>
