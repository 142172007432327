export class EventBus<DetailType = any> {
  public listeners: {
    type: EventBusEventType;
    listener: (event: CustomEvent<DetailType>) => void;
    guid: string;
  }[] = [];
  private eventTarget: EventTarget;

  constructor(description = "") {
    this.eventTarget = document.appendChild(document.createComment(description));
  }

  on(type: EventBusEventType, listener: (event: CustomEvent<DetailType>) => void): string {
    this.eventTarget.addEventListener(type.toString(), listener as any);

    const guid = crypto.randomUUID();
    this.listeners.push({ type, listener, guid });

    return guid;
  }

  once(type: EventBusEventType, listener: (event: CustomEvent<DetailType>) => void): string {
    this.eventTarget.addEventListener(type.toString(), listener as any, {
      once: true,
    });

    const guid = crypto.randomUUID();
    this.listeners.push({ type, listener, guid });

    return guid;
  }

  off(type: EventBusEventType, listener: (event: CustomEvent<DetailType>) => void): void {
    this.eventTarget.removeEventListener(type.toString(), listener as any);
  }

  offByGUID(listenerGuid: string): void {
    const listenerIndex = this.listeners.findIndex((x) => x.guid === listenerGuid);

    if (listenerIndex === -1) return;

    this.off(this.listeners[listenerIndex].type, this.listeners[listenerIndex].listener);
    this.listeners.splice(listenerIndex, 1);
  }

  emit(type: EventBusEventType, detail?: DetailType): boolean {
    return this.eventTarget.dispatchEvent(new CustomEvent(type.toString(), { detail }));
  }
}

export enum EventBusEventType {
  click,
  mousemove,
  resize,
}
