import type { Dayjs } from "dayjs";
import { $dayjs } from "../utils/dayjs";

export const DateTimeFormatDirective = {
  mounted(el: any, binding: any) {
    updateDateTime(el, binding);
  },
  updated(el: any, binding: any) {
    updateDateTime(el, binding);
  },
};

function updateDateTime(el: HTMLElement, binding: Partial<{ value: string }>) {
  const value = binding.value;
  const attrs = el.attributes;
  const utc = (attrs.getNamedItem("utc")?.value ?? false) == "true" ? true : false;
  const emptyValue = attrs.getNamedItem("emptyValue")?.value ?? "";

  const defaultFormat = "DD-MM-YYYY HH:mm";
  let dateObj;

  if (!utc) {
    dateObj = $dayjs(value);
  } else {
    dateObj = $dayjs.utc(value);
  }

  let formattedDate = emptyValue;

  if (dateObj.isValid()) {
    formattedDate = format(dateObj, defaultFormat);
  }

  el.textContent = formattedDate;
}

function format(dayjs: Dayjs, format: string) {
  return dayjs.format(format);
}
