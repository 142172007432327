export enum RouteName {
  Home = "home",
  ComponentPlayground = "componentPlayground",
  Track = "Track",
  Callback = "Callback",
  Dashboard = "Dashboard",
  AddIOSS = "AddIOSS",
  EditIOSS = "EditIOSS",
  ClientReports = "ClientReports",
  IOSSReports = "IOSSReports",
}
