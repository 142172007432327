import { defineStore } from "pinia";
import { ref } from "vue";
import { getVisitorToken } from "@/api/auth";
import type { IVisitorToken } from "@/typing/visitorToken.interface";
import { useMiscStore } from "./misc.store";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { useRouter } from "vue-router";

export const useAuthStore = defineStore("auth", () => {
  const visitorToken = ref<IVisitorToken | null>(null);
  const visitorTokenPromise = ref<Promise<any> | null>(null);
  const isLoggedIn = ref<boolean>(false);
  const auth0Client = ref<Auth0Client | undefined>(undefined);

  async function getToken(): Promise<string> {
    if (isLoggedIn.value) {
      return (await auth0Client.value?.getTokenSilently()) ?? "";
    }

    if (!visitorTokenIsValid()) {
      if (visitorTokenPromise.value) {
        await visitorTokenPromise.value;
      } else {
        await fetchVisitorToken();
      }
    }

    return visitorToken.value?.access_token ?? "";
  }

  function visitorTokenIsValid(): boolean {
    if (!visitorToken.value) return false;

    const date = new Date(visitorToken.value.created_at);
    date.setSeconds(date.getSeconds() + visitorToken.value.expires_in - 5);

    return date >= new Date();
  }

  async function fetchVisitorToken(): Promise<void> {
    const fn = async () => {
      try {
        const res = await getVisitorToken();
        visitorToken.value = { ...res.data };
        visitorToken.value.created_at = new Date();
      } catch (error) {
        visitorToken.value = null;
        console.error(error);
        throw error;
      }
    };

    try {
      visitorTokenPromise.value = fn();
      await visitorTokenPromise.value;
    } finally {
      visitorTokenPromise.value = null;
    }
  }

  async function login(returnPathName = "/"): Promise<void> {
    if (await auth0Client.value?.isAuthenticated()) {
      isLoggedIn.value = true;
      return;
    }

    auth0Client.value?.loginWithRedirect({
      appState: {
        route: returnPathName,
      },
      authorizationParams: {
        redirect_uri: window.location.origin + "/auth/callback",
      },
    });
  }

  async function logout(): Promise<void> {
    await auth0Client.value?.logout({
      logoutParams: { federated: true, returnTo: window.location.origin },
    });
  }

  async function handleRedirectCallback(): Promise<void> {
    const router = useRouter();
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has("code")) {
      let result;
      try {
        result = await auth0Client.value?.handleRedirectCallback();
        isLoggedIn.value = true;
      } catch (error) {
        isLoggedIn.value = false;
      } finally {
        let pathName: string;
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("code");
        searchParams.delete("state");

        if (result && isLoggedIn.value) {
          pathName = `${result.appState.route}`;
        } else {
          pathName = "/";
        }

        router.replace({ name: pathName });
        history.replaceState(null, document.title, pathName);
      }
    } else {
      await auth0Client.value?.checkSession();
      isLoggedIn.value = (await auth0Client.value?.isAuthenticated()) ?? false;
    }
  }

  async function createAuth0Client(): Promise<Auth0Client> {
    const client = new Auth0Client({
      domain: useMiscStore().env.auth0.domain,
      clientId: useMiscStore().env.auth0.clientId,
      authorizationParams: {
        audience: useMiscStore().env.auth0.audience,
        redirect_uri: window.location.origin + "/auth/callback",
      },
    });

    auth0Client.value = client;

    try {
      isLoggedIn.value = await client.isAuthenticated();
    } catch (error) {
      isLoggedIn.value = false;
    }

    return client;
  }

  return {
    isLoggedIn,
    auth0Client,
    visitorToken,
    login,
    logout,
    handleRedirectCallback,
    createAuth0Client,
    getToken,
    fetchVisitorToken,
    visitorTokenIsValid,
  };
});
