import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";
import { isDebugging } from "@/utils/isDebugging";
import { RouteName } from "@/typing/enums";
import { authGuard } from "@/utils/guards/authGuard";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: RouteName.Home,
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/track",
    name: RouteName.Track,
    component: () => import("../views/TrackView.vue"),
  },
  {
    path: "/auth/callback",
    name: RouteName.Callback,
    component: () => import("../views/Callback.vue"),
  },
  {
    path: "/dashboard",
    name: RouteName.Dashboard,
    component: () => import("../views/authed/Dashboard.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/ioss/add",
    name: RouteName.AddIOSS,
    component: () => import("../views/authed/AddIOSS.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/ioss/edit",
    name: RouteName.EditIOSS,
    component: () => import("../views/authed/AddIOSS.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/ioss/reports",
    name: RouteName.IOSSReports,
    component: () => import("../views/authed/IOSSReports.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/client/reports",
    name: RouteName.ClientReports,
    component: () => import("../views/authed/ClientReports.vue"),
    beforeEnter: authGuard,
  },
];

if (isDebugging()) {
  routes.push({
    path: "/playground",
    name: RouteName.ComponentPlayground,
    component: () => import("../views/ComponentPlayground.vue"),
  });
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
