import { useAuthStore } from "@/stores/auth.store";
import Axios, { AxiosError, type AxiosResponse } from "axios";

export function initAxiosInterceptor() {
  Axios.interceptors.request.use(
    async (config: any) => {
      const isAuthCall = config.url?.includes("auth/token");

      if (!isAuthCall) {
        const jwt = await useAuthStore().getToken();
        config.headers.Authorization = `Bearer ${jwt}`;
      }

      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    },
  );
  Axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    },
  );
}
