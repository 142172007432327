import { useAuthStore } from "@/stores/auth.store";

export function authGuard(to: any, from: any, next: Function) {
  const store = useAuthStore();

  if (!store.isLoggedIn) {
    store.login(to.name);
    return;
  }

  next();
}
