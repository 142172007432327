export default function setupGoogleAnalytics(trackingId: string) {
  const script = document.createElement("script");

  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  script.async = true;

  script.onload = function () {
    (window as any).dataLayer = (window as any).dataLayer || [];

    function gtag(...args: any) {
      (window as any).dataLayer.push(...args);
    }

    (window as any).gtag = gtag;

    (gtag as any)("js", new Date());

    (gtag as any)("config", trackingId, {
      cookie_flags: "max-age=7200;secure;samesite=none",
      anonymize_ip: true,
    });
  };

  document.head.appendChild(script);
}
