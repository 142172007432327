import "./styling/main.scss";

import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import { useMiscStore } from "./stores/misc.store";
import { EventBus } from "./utils/eventBus";
import { initAxiosInterceptor } from "./utils/axiosInterceptor";
import Notifications from "@kyvg/vue3-notification";
import setupGoogleAnalytics from "./utils/googleAnalytics";
import { useAuthStore } from "./stores/auth.store";
import { DateTimeFormatDirective } from "./directives/dateTimeFormat";

import "@vuepic/vue-datepicker/dist/main.css";

export let eventBus: EventBus;

async function main(): Promise<void> {
  try {
    eventBus = new EventBus("main");

    const app = createApp(App);

    app.use(createPinia());

    app.use(Notifications);

    await useMiscStore().fetchEnv();
    await useAuthStore().createAuth0Client();

    initAxiosInterceptor();

    setupGoogleAnalytics(useMiscStore().env.gaTrackingId);

    app.use(router);

    app.directive("dateTime", DateTimeFormatDirective);
    app.mount("#app");
  } catch (error) {
    console.error("Could not initialize application:", error);
  }
}

main();
