<template>
  <div class="wrapper">
    <span> <b>DEV</b> </span>
    <button @click="logEnvironment">Log environment</button>
    <button @click="logState">Log full state</button>
    <button @click="$emit('playground')">Component Playground</button>

    <button @click="$emit('close')" class="mla">Close this bar</button>
  </div>
</template>

<script setup lang="ts">
  import { useMiscStore } from "@/stores/misc.store";
  import { toRaw } from "vue";

  function logEnvironment() {
    readableConsoleLog(toRaw(useMiscStore().env));
  }

  function logState() {
    readableConsoleLog(useMiscStore());
  }

  function readableConsoleLog(val: any): any {
    console.log(
      JSON.parse(
        JSON.stringify(val, (key: any, value: any) => {
          if (key) {
            if (key[0] === "_" || key[0] === "$") {
              return undefined;
            }
          }

          return value === undefined ? null : value;
        }),
      ),
    );
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3c41a;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 7px;
    z-index: 100;

    > span {
      font-size: 13px;
      margin-right: 15px;
    }

    > button {
      font-size: 10px;
      padding: 5px 7px;
      text-transform: none;
      min-height: 0;
      border: none;
      // margin-bottom: 0;
      margin: 0 10px;

      &.mla {
        margin-left: auto;
        border-left: solid 15px transparent;
        background-clip: padding-box;
      }
    }
  }
</style>
