import { defineStore } from "pinia";
import type { IEnvironment } from "@/typing/environment.interface";
import { ref } from "vue";

export const useMiscStore = defineStore("misc", () => {
  const env = ref<IEnvironment>({} as IEnvironment);
  const screenWidth = ref<number>(document.getElementsByTagName("body")[0].clientWidth);
  const showLoader = ref<boolean>(false);

  async function fetchEnv() {
    try {
      const res = await fetch("/env.json");
      env.value = await res.json();
    } catch (error) {
      throw "Could not load environment " + error;
    }
  }

  function setScreenWidth(): void {
    screenWidth.value = document.getElementsByTagName("body")[0].clientWidth;
  }

  return { env, screenWidth, showLoader, fetchEnv, setScreenWidth };
});
